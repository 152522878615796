export default {
  blogerName: 'LUDER',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@luder_'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UC5V79kHcXREWLVro4YDDHBQ'
    },
    {
      name: 'telegram',
      url: 'https://t.me/mobilka_casino'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c732f3169',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/c411e5489',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/c9cba9b21',
      gameTitle: 'Royal High-Road (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c35024204',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c7479cdd3',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/cb508e751',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/cd63e7d82',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-azhqmrpiks.com/cf9599a89',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/c7828cbd6',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c0f8e030c',
      gameTitle: 'Mechanical Clover (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/cdd504b25',
      gameTitle: 'Dragon`s Gold 100 (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>MOBILKA</strong> и получи 50 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'MOBILKA',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>50FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
